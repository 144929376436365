import { useContext, useReducer } from 'react'
import { AppContext, DialogueContext, PageContext } from "../../App"
import Network from '../../utilities/Network'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import TextInput from '../input/TextInput'
import TextArea from '../input/TextArea'
import { alternateUrl } from '../../constants'

const MODIFY_NOTIFICATION = 8
const REFRESH = 7

const UseSidebar = () => {
    const dialogueContext = useContext(DialogueContext)
    const pageContext = useContext(PageContext)
    const appContext = useContext(AppContext)

    function isLoggedIn() {
        return localStorage.getItem("admin") != null
    }

    const initialState = {
        notification: {
            title: '',
            body: ''
        },
    }

    const showNotificationDialogue = (title, body) => {
        dialogueContext.editDialogue({
            visible: true,
            children: <Button onClick={e => {
                saveNotification(title, body)
                // showNotificationDialogue('', '')
                dispatch({
                    type: MODIFY_NOTIFICATION,
                    param: "title",
                    value: ''
                })
                dispatch({
                    type: MODIFY_NOTIFICATION,
                    param: "body",
                    value: ''
                })
            }
            } >إرسال</Button>,
            content: <Form>
                <TextInput label="عنوان الإشعار"
                    value={title}
                    required
                    onChange={e => {
                        showNotificationDialogue(e.target.value, body)
                    }} />
                <TextArea label="محتوى الإشعار"
                    required
                    value={body}
                    onChange={e => {
                        showNotificationDialogue(title, e.target.value)
                    }} />
            </Form>
        })
    }


    const reducer = (state, action) => {
        switch (action.type) {
            case REFRESH:
                return {
                    ...state
                }
            case MODIFY_NOTIFICATION://param value
                var n = state.notification;
                n[action.param] = action.value;
                if (n.title == '' && n.body == '') dialogueContext.hideDialogue()
                else showNotificationDialogue(n.title, n.body)
                return {
                    ...state,
                    notification: {
                        ...state.notification,
                        [action.param]: action.value
                    }
                }
            default:
                return state
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    const saveNotification = (title, body) => {
        new Network({
            url: `/admin/notifications`,
            method: "post",
            context: pageContext,
            dialogueContext: dialogueContext,
            deliable: false,
            baseURL: alternateUrl,
            title: `إرسال الإشعار ${title} إلى كل المستخدمين`,
            params: {
                'title': title,
                'body': body,
            },
            onConnectionSucceed: (data) => {
                dialogueContext.hideDialogue()
                pageContext.showLoading(false)
                pageContext.showToast({
                    type: "success",
                    "title": "تم إرسال الإشعار",
                    "description": ""
                })
            }
        }).invoke();
    }
    return {
        state: state,
        isLoggedIn: isLoggedIn,
        showNotificationDialogue: showNotificationDialogue,
        dispatch: dispatch
    }
}
export default UseSidebar