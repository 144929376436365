class AppConfig {
    name;
    icon;
    color;
    id;

    fromMap = (map) => {
        this.id = map.id
        this.name = map.name
        this.icon = map.icon
        this.color = map.color
        return this;
    }

    getName = () => this.name
    getIcon = () => this.icon
    getColor = () => this.color
    getId = () => this.id
}

export default AppConfig